import { StringUtil } from './StringUtil';

export const EDU_STATUS_DATE_BEFORE = 0; //시작안됨
export const EDU_STATUS_WAIT = 1; //대기
export const EDU_STATUS_ING = 2; //진행중
export const EDU_STATUS_DONE = 3; //수료
export const EDU_STATUS_DATE_OVER = 4; //지남

export class MoodleData {
    /**
     * 무들의 카테고리 리스트를 1,2,3 뎊스 합쳐서 한 뎁스로 만든다
     */
    static linkCategory(categoryList) {
        let depth1 = [];
        let depth2 = [];
        let depth3 = [];

        for (let categoryInfo of categoryList) {
            if (categoryInfo.visible != 1) continue;

            if (categoryInfo.depth == 1) {
                depth1.push(categoryInfo);
            } else if (categoryInfo.depth == 2) {
                depth2.push(categoryInfo);
            } else if (categoryInfo.depth == 3) {
                depth3.push(categoryInfo);
            }
        }

        let cateList = [];
        for (let d3 of depth3) {
            for (let d2 of depth2) {
                if (d3.parent == d2.id) {
                    for (let d1 of depth1) {
                        if (d2.parent == d1.id) {
                            let d1Info = { ...d1 };
                            let d2Info = { ...d2 };
                            d2Info.d3 = d3;
                            d1Info.d2 = d2Info;
                            cateList.push(d1Info);
                        }
                    }
                }
            }
        }

        //cateList = cateList.sort((a, b) => a.d2.d3.id - b.d2.d3.id);
        return cateList;
    }

    /**
     * 무들의 카테고리 리스트와 코스를 연결 시켜서 돌려준다.
     */
    static linkCategoryAndCourses(courses, categoryAll) {
        let cateList = [];
        for (let category of categoryAll) {
            category.d2.d3.courses = [];
            let isBeforeStartTime = false;
            let isAfterEndTime = false;
            let minStartDate = 0;
            let maxEndDate = 0;
            for (let course of courses) {
                MoodleData.setPlayContents(course);
                if (course.category == category.d2.d3.id) {
                    if (category.d2.d3.courses.length == 0) {
                        category.date = '';
                        if (course.startdate && course.enddate) {
                            minStartDate = Number(course.startdate);
                            maxEndDate = Number(course.enddate);
                            course.date =
                                StringUtil.formatTimestampToStr(Number(course.startdate + '000')) +
                                ' ~ ' +
                                StringUtil.formatTimestampToStr(Number(course.enddate + '000'));
                        } else {
                            let startDate = Number(course.startdate);
                            let endDate = Number(course.startdate);
                            if (startDate < minStartDate) minStartDate = startDate;
                            if (endDate > maxEndDate) maxEndDate = endDate;
                        }
                    }

                    category.date =
                        StringUtil.formatTimestampToStr(Number(minStartDate + '000')) +
                        ' ~ ' +
                        StringUtil.formatTimestampToStr(Number(maxEndDate + '000'));

                    let diffMilli = StringUtil.getDiffMilli(Number(minStartDate + '000'));
                    if (diffMilli > 0) {
                        isBeforeStartTime = true;
                    }
                    diffMilli = StringUtil.getDiffMilli(Number(maxEndDate + '000'));
                    if (diffMilli < 0) {
                        isAfterEndTime = true;
                    }

                    category.leftDays = StringUtil.getLeftDay(Number(maxEndDate + '000'));
                    category.d2.d3.courses.push(course);
                    course.percent = MoodleData.getCoursePercent(course);
                    course.percent100 = (Number(course.percent) / 100) | 0;
                    course.percent100Text = MoodleData.getPercent100Text(course);
                }
            }

            category.minStartDate = minStartDate;
            category.maxEndDate = maxEndDate;
            category.isAfterEndTime = isAfterEndTime;

            category.bRealtime = MoodleData.isRealtime(category.d2.d3.courses);
            category.researchText = MoodleData.getResearchStateText(category.d2.d3.courses);

            if (category.d2.d3.courses.length > 0) {
                let course = category.d2.d3.courses[0];
                category.percent = course.percent;
                category.percent100 = course.percent100;
                category.percent100Text = category.percent100 + '%';
                if (category.percent100 >= 100) {
                    category.state = EDU_STATUS_DONE;
                    category.stateText = '수강완료';
                } else if (isBeforeStartTime) {
                    category.state = EDU_STATUS_DATE_BEFORE;
                    category.stateText = '대기중';
                } else if (isAfterEndTime) {
                    category.state = EDU_STATUS_DATE_OVER;
                    category.stateText = '-';
                } else if (category.percent100 <= 0) {
                    category.state = EDU_STATUS_WAIT;
                    category.stateText = '진행중';
                } else {
                    category.state = EDU_STATUS_ING;
                    category.stateText = '진행중';
                }

                cateList.push(category);
            }
        }

        cateList = cateList.sort((a, b) => b.minStartDate - a.minStartDate);

        return cateList;
    }

    static getCoursePercent(course) {
        if (!course) return 0;

        let percent = 0;
        let contentNum = 0;
        for (let content of course.contents) {
            if (content.modname == 'zoom' || content.modname == 'vod' || content.modname == 'quiz') {
                contentNum++;
                let contentPercent = Number(content.percent);
                if (content.modname == 'zoom' && contentPercent == 100) {
                    contentPercent = 10000;
                }
                if (content.modname == 'quiz' && contentPercent > 0) {
                    contentPercent = 10000;
                }
                percent += contentPercent;
            }
        }

        if (contentNum == 0) return 0;

        let result = Number(percent / contentNum) | 0;

        return result;
    }

    static getPercent100Text(course) {
        if (!course) return '%';

        for (let content of course.contents) {
            if (content.modname == 'zoom') {
                if (content.percent) {
                    return content.percent + '%';
                }
                return '0%';
            } else if (content.modname == 'vod') {
                if (content.percent) {
                    return ((Number(content.percent) / 100) | 0) + '%';
                }
                return '0%';
            }
        }

        return '0%';
    }

    //실시간 강의가 하나라도 있으면 실시간 강의
    static isRealtime(courses) {
        if (!courses) return false;

        for (let course of courses) {
            for (let content of course.contents) {
                if (content.modname == 'zoom') {
                    return true;
                }
            }
        }

        return false;
    }

    static getResearchStateText(courses) {
        if (!courses) return '없음';

        let researchNum = 0;
        let done = true;
        for (let course of courses) {
            for (let content of course.contents) {
                if (content.modname == 'research') {
                    researchNum++;
                    if (Number(content.percent) < 100) {
                        done = false;
                        break;
                    }
                }
            }
        }

        if (done) return '작성완료';
        return '미작성';
    }

    static getPlayInfo(course) {
        if (course) {
            for (let content of course.contents) {
                console.log('content.modname ', content.modname);
                if (content.modname) {
                    if (content.modname == 'zoom' || content.modname == 'vod') {
                        return content;
                    }
                }
            }
        }

        return null;
    }

    static getPlayInfoZoom(course) {
        if (course) {
            for (let content of course.contents) {
                console.log('content.modname ', content.modname);
                if (content.modname) {
                    if (content.modname == 'zoom') {
                        return content;
                    }
                }
            }
        }
        return null;
    }

    static setPlayContents(course) {
        if (course) {
            course.playContents = [];
            for (let content of course.contents) {
                if (content.modname) {
                    if (content.modname == 'zoom' || content.modname == 'vod') {
                        let percent100 = (Number(content.percent) / 100) | 0;
                        let newContent = {
                            ...content,
                            percent100: percent100,
                            percent100Text: percent100 + '%'
                        };

                        if (content.modname == 'zoom') {
                            newContent.zoomStartTime = Number(content.customdata.start_time);
                            newContent.zoomEndTime = course.zoomStartTime + Number(content.customdata.duration);
                        }

                        course.playContents.push(newContent);
                    }
                }
            }
        }
        return null;
    }
}
