import { useState, useEffect, createContext, useContext, useReducer } from 'react';
import menuItemFunc from './menu-items';
import PropTypes from 'prop-types';
import categoryItems from 'store/category/categoryItems';

let authInfoExists = localStorage.getItem('authInfo') && localStorage.getItem('authInfo') !== 'undefined';
let userId = authInfoExists ? JSON.parse(localStorage.getItem('authInfo')).userId : '';
let userName = authInfoExists ? JSON.parse(localStorage.getItem('authInfo')).userName : '';
export const accessToken = authInfoExists ? JSON.parse(localStorage.getItem('authInfo')).accessToken : '';
let accessTokenExpiresIn = authInfoExists ? JSON.parse(localStorage.getItem('authInfo')).accessTokenExpiresIn : 0;
let refreshToken = authInfoExists ? JSON.parse(localStorage.getItem('authInfo')).refreshToken : '';

export const initialState = {
    userId: userId || '',
    userName: userName || '',
    accessToken: accessToken || '',
    accessTokenExpiresIn: accessTokenExpiresIn,
    refreshToken: refreshToken || '',
    loading: false,
    errorMessage: null
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...initialState,
                loading: true
            };
        case 'LOGIN_SUCCESS':
            localStorage.setItem('authInfo', JSON.stringify(action.payload));
            return {
                ...initialState,
                userId: action.payload.userId,
                userName: action.payload.userName,
                accessToken: action.payload.accessToken,
                accessTokenExpiresIn: action.payload.accessTokenExpiresIn,
                refreshToken: action.payload.refreshToken,
                loading: false
            };
        case 'LOGOUT':
            localStorage.removeItem('authInfo');
            return {
                ...initialState,
                userId: '',
                userName: '',
                accessToken: '',
                accessTokenExpiresIn: '',
                refreshToken: ''
            };
        case 'LOGIN_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };
        default:
            throw new Error('Unhandled action type: ${action.type}');
    }
};

export const AuthContext = createContext(initialState);

export function UseAuthInfo() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('UseAuthInfo는 AuthProvider 안에서만 사용 가능합니다.');
    }

    return context;
}

export const AuthProvider = ({ children }) => {
    const [authInfo, dispatch] = useReducer(AuthReducer, initialState);
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {};
        console.error = () => {};
        console.debug = () => {};
    }
    return <AuthContext.Provider value={[authInfo, dispatch]}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node
};

export const MenuContext = createContext();

export function UseMenuGroupInfo() {
    const context = useContext(MenuContext);
    if (context === undefined) {
        throw new Error('UseMenuInfo MenuProvider 안에서만 사용 가능합니다.');
    }

    return context;
}

export const MenuProvider = ({ children }) => {
    const [menuGroups, setMenuGroups] = useState({});
    const [authInfo, dispatch] = UseAuthInfo();

    useEffect(() => {
        async function setMenu() {
            setMenuGroups(await menuItemFunc(authInfo, dispatch));
        }
        setMenu();
    }, [authInfo]);
    return <MenuContext.Provider value={menuGroups}>{children}</MenuContext.Provider>;
};

MenuProvider.propTypes = {
    children: PropTypes.node
};

export const CategoryContext = createContext();

export function UseCategoryGroupInfo() {
    const context = useContext(CategoryContext);
    if (context === undefined) {
        throw new Error('UseCategoryInfo CategoryProvider 안에서만 사용 가능합니다.');
    }

    return context;
}

export const CategoryProvider = ({ children }) => {
    const [categoryGroups, setCategoryGroups] = useState({});

    useEffect(() => {
        async function setCategory() {
            setCategoryGroups(await categoryItems());
        }
        setCategory();
    }, []);
    return <CategoryContext.Provider value={categoryGroups}>{children}</CategoryContext.Provider>;
};

CategoryProvider.propTypes = {
    children: PropTypes.node
};
