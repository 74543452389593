import { useNavigate } from 'react-router-dom';
import { UseAuthInfo, UseMenuGroupInfo } from '../../context.js';
import PropTypes from 'prop-types';
import useSelectPopup from '../popup/SelectPopup';
import { loginNeedPage } from '../../config.js';
import { useEffect, useState } from 'react';

const isDev = process.env.REACT_APP_IS_DEV;

const MainHeader = () => {
    const [authInfo, dispatch] = UseAuthInfo();
    const navigate = useNavigate();
    const menuGroup = UseMenuGroupInfo();
    const [menus, setMenus] = useState(null);
    const [openSelectPopup, SelectPopup] = useSelectPopup();

    useEffect(() => {
        if (menuGroup?.items) {
            setMenus(menuGroup.items);
        }
    }, [menuGroup, menus]);

    useEffect(() => {
        setTimeout(() => {
            initHeader();
            setMobileCss();
        }, 500);
    }, []);

    let isLogin = authInfo?.userId && authInfo?.accessToken;

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/');
    };

    const handleMenuClick = (url) => {
        focusOutMainMenu();

        if (!isLogin) {
            if (loginNeedPage.includes(url)) {
                openSelectPopup('로그인이 필요한 페이지 입니다. 로그인 하시겠습니까?', (msg) => {
                    if (msg === 'ok') {
                        navigate('/login?url=' + url + '&page=login');
                    }
                });
                return;
            }
        }
        navigate(url);
    };

    return (
        <div id="header">
            <div className="inner">
                <div className="hd-top" style={{ minWidth: '800px' }}>
                    <div className="hd-top-lnk">
                        <a href="/" style={{ cursor: 'pointer' }}>
                            한국벤처캐피탈연수원
                        </a>
                        <a href="https://license.kvca.or.kr/" style={{ cursor: 'pointer' }}>
                            {' '}
                            자격시험 센터
                        </a>
                    </div>
                    <div className="hd-top-sch"></div>
                    <div className="hd-top-utill">
                        {isLogin ? (
                            // <a href="/my/edit" style={{ cursor: 'pointer' }}>
                            //     내정보수정
                            // </a>
                            <></>
                        ) : (
                            <>
                                {isDev == 'true' ? (
                                    <a href="/register" style={{ cursor: 'pointer' }}>
                                        회원가입
                                    </a>
                                ) : null}
                            </>
                        )}

                        {isLogin ? (
                            <a onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                로그아웃
                            </a>
                        ) : (
                            <a href="/login" style={{ cursor: 'pointer' }}>
                                로그인
                            </a>
                        )}

                        <a href="/contactus" style={{ cursor: 'pointer' }}>
                            Contact Us
                        </a>
                    </div>
                </div>
                <div id="gnbWrap">
                    <h1 className="logo" style={{ cursor: 'pointer' }}>
                        <a href="/">한국벤처캐피탈연수원</a>
                    </h1>
                    <ul id="gnb">{<Menu items={menus} handleMenuClick={handleMenuClick} />}</ul>
                </div>
            </div>
            {SelectPopup}
        </div>
    );
};

const Menu = ({ items, handleMenuClick }) => {
    if (!items) return null;

    const handleMouseEnter = () => {};

    return (
        <>
            {items.map((item, index) => (
                <li onMouseEnter={handleMouseEnter} key={index} className={'gnb' + (index + 1)}>
                    <a>{item.title}</a>
                    <ul className="subDepth">
                        {item.children ? (
                            <>
                                {item.children.map((c, index) => (
                                    <li key={index}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleMenuClick(c.url)}>
                                            {c.title}
                                        </a>
                                    </li>
                                ))}
                            </>
                        ) : null}
                    </ul>
                </li>
            ))}
        </>
    );
};

export default MainHeader;
