// third-party
import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project import
import reducers from './reducers';
import req from 'utils/restApi';
import { MoodleData } from 'utils/MoodleData';

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
    let result = await req(
        '/api/category/list',
        { categoryid: 'all' },
        (response) => {
            if (response.data?.length > 0) {
                return response.data;
            }
            return [];
        },
        undefined,
        undefined,
        undefined
    );

    result = MoodleData.linkCategory(result);

    return result;
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        categories: categoriesSlice.reducer
    }
});

const { dispatch } = store;

export { store, dispatch };
