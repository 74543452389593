import { useCallback, useEffect, useRef, useState } from 'react';
import req from '../../utils/restApi';
import MainNoticePopup from '../popup/MainNoticePopup';
import { FileUtil } from 'utils/FileUtil';

const MiniNotice = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [popupNotice, setPopupNotice] = useState(null);

    useEffect(() => {
        if (popupNotice == null) {
            reqNotice();
        }
    }, [popupNotice]);

    const reqNotice = useCallback(() => {
        req('/api/notice/all', {}, (response) => {
            if (response.data?.length > 0) {
                setList(response.data);
            }
        });
    }, []);

    const onClose = () => {
        setIsOpen(false);
    };

    const setList = (list) => {
        if (list) {
            let popupNotice = null;
            for (let n of list) {
                if (popupNotice === null && n.popup) {
                    popupNotice = n;
                }
            }
            getAttachedFiles(popupNotice);
        }
    };

    const getAttachedFiles = (notice) => {
        req(
            '/api/com/getAttachments',
            { type: 'NOTICE', typeId: notice.noticeId },
            (response) => {
                let newAttachedFiles = [];
                if (response.data && response.data.length > 0) {
                    for (let content of response.data) {
                        let url = FileUtil.getBinaryStrToUrl(content.file.content);
                        newAttachedFiles.push({ fileName: content.file.fileName, url: url });
                    }
                }

                if (newAttachedFiles.length > 0) {
                    notice.noticeContent += '<br/><br/><ul class="dv-file"></ul><li><span>첨부파일</span></li>';
                    let strLi =
                        '<li><a href={file.url} download="{file.fileName}">{file.fileName} <img src="/images/file.svg" alt="첨부파일" class="file" /></a></li>';

                    for (let file of newAttachedFiles) {
                        let li = strLi.replaceAll('{file.fileName}', file.fileName);
                        li = li.replaceAll('{file.url}', file.url);
                        notice.noticeContent += li;
                    }
                    notice.noticeContent += '</ul><br/><br/>';
                }

                setPopupNotice(notice);
            },
            (error) => {
                console.log(error);
            },
            undefined,
            undefined
        );
    };

    return <>{isOpen ? <MainNoticePopup notice={popupNotice} onClose={onClose} /> : null}</>;
};

export default MiniNotice;
