import React, { useEffect, useState, lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import MinimalLayout from '../layout/MainLayout/MinimalLayout';

import { useRoutes, useNavigate } from 'react-router-dom';
import { UseAuthInfo } from '../context.js';
import req from '../utils/restApi';
import { loginNeedPage } from '../config';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
    const navigate = useNavigate();
    const [authInfo] = UseAuthInfo();

    const defaultComponents = [];

    const [components, setComponents] = useState(defaultComponents);
    const [emptyComponents, setEmptyComponents] = useState([]);
    const [screens, setScreens] = useState(null);

    const mainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: components
    };

    const emptyRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: emptyComponents
    };

    useEffect(() => {
        if (screens == null) {
            req('/api/menu/typeScreens', { screenType: 'MAIN_USER_MAIN' }, (response) => {
                if (response.data?.MAIN_USER_MAIN) {
                    setScreens(response.data.MAIN_USER_MAIN);
                    loadComponents(response.data.MAIN_USER_MAIN, setComponents, defaultComponents);
                }
            });

            req('/api/menu/typeScreens', { screenType: 'MAIN_USER_EMPTY' }, (response) => {
                if (response.data?.MAIN_USER_EMPTY) {
                    loadComponents(response.data.MAIN_USER_EMPTY, setEmptyComponents, []);
                }
            });
        }
    }, [screens]);

    useEffect(() => {
        let pathName = window.location.pathname;
        if (loginNeedPage.includes(pathName)) {
            if (!authInfo.userId || !authInfo.accessToken) {
                navigate('/login');
            }
        }
    }, [authInfo.accessToken]);

    const loadComponents = async (routes, setFunc, defaultComponents) => {
        const loadedComponents = routes.map((route) => {
            return {
                path: route.path,
                element: React.createElement(Loadable(lazy(() => import(`../pages/${route.srcPath}.js`))), null)
            };
        });
        setFunc([...defaultComponents, ...loadedComponents]);
    };
    return useRoutes([mainRoutes, emptyRoutes]);
}
