// project import
import React, { useEffect } from 'react';
import Routes from './routes';
import ScrollTop from './components/ScrollTop';
import { fetchCategories } from 'store/index';
import { useDispatch } from 'react-redux';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    return (
        <ScrollTop>
            <Routes />
        </ScrollTop>
    );
};

export default App;
