export class FileUtil {
    static getBinaryStrToUrl(binaryString) {
        if (!binaryString) return '';

        const byteCharacters = atob(binaryString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(blob);
        return fileURL;
    }
}
