const days = ['일', '월', '화', '수', '목', '금', '토'];
export class StringUtil {
    static splitDayStr(str) {
        if (str && str.length > 10) {
            return str.substring(0, 10);
        }
        return '';
    }

    static getDateValidateYupD8(Yup) {
        return Yup.string()
            .required('날짜를 입력해주세요.')
            .matches(/^\d{8}$/, '유효한 날짜 형식이 아닙니다.');
    }

    static getYYYYMMDDToServerFormat(str) {
        if (str && str.length == 8) {
            return `${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)} 00:00:00`;
        }
        return str;
    }

    static getYYYYMMDDToComma(str) {
        if (str && str.length == 8) {
            return `${str.substr(0, 4)}.${str.substr(4, 2)}.${str.substr(6, 2)}`;
        }
        return str;
    }

    static getYYYYMMDDToKr(str) {
        if (str && str.length == 8) {
            return `${str.substr(0, 4)}년 ${str.substr(4, 2)}월 ${str.substr(6, 2)}일`;
        }
        return str;
    }

    static getServerFormatToYYYYMMDD(str) {
        if (str && str.length >= 10) {
            const date = new Date(str);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return year + month + day;
        }
        return '';
    }

    static getServerFormatToYMDComma(str) {
        if (str && str.length >= 10) {
            const date = new Date(str);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1);
            const day = String(date.getDate());
            return `${year}.${month}.${day}`;
        }
        return '';
    }

    static getServerFormatToKrYMD(str, weekDay = true) {
        if (str && str.length >= 10) {
            const date = new Date(str);
            return StringUtil.getDateToYMDKr(date, weekDay);
        }
        return '';
    }

    static getServerFormatToKrMD(str) {
        if (str && str.length >= 10) {
            const date = new Date(str);
            const month = String(date.getMonth() + 1);
            const day = String(date.getDate());
            const weekKr = days[date.getDay()];
            return `${month}월 ${day}일(${weekKr})`;
        }
        return '';
    }

    static getDateToYMDKr(date, weekDay = true) {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1);
            const day = String(date.getDate());
            if (weekDay) {
                const weekKr = days[date.getDay()];
                return `${year}년 ${month}월 ${day}일(${weekKr})`;
            } else {
                return `${year}년 ${month}월 ${day}일`;
            }
        }
        return '';
    }

    static getDateToMDKr(date, weekDay = true) {
        if (date) {
            const month = String(date.getMonth() + 1);
            const day = String(date.getDate());
            if (weekDay) {
                const weekKr = days[date.getDay()];
                return `${month}월 ${day}일(${weekKr})`;
            } else {
                return `${month}월 ${day}일`;
            }
        }
        return '';
    }

    static getDateToHHMM(date) {
        if (date) {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return hours + ':' + minutes;
        }
        return '';
    }

    static getDateToYYYYMMDD(date, separator = '') {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            return year + separator + month + separator + day;
        }
        return '';
    }

    static formatDateToYYYYMMDDHHMMSS() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    static formatDateToYYYYMMDDHHMM(timestamp) {
        let date = null;
        if (timestamp) date = new Date(Number(timestamp));
        else date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    static formatTimestampToStr(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}.${month}.${day}`;
    }

    static getLeftDay(timestamp) {
        const date = new Date(timestamp);
        let diffDay = Math.floor((date.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
        return diffDay;
    }

    static isBefore(timestamp) {
        const date = new Date(timestamp);
        const diffMilli = date.getTime() - Date.now();
        return diffMilli > 0;
    }

    static isAfter(timestamp) {
        const date = new Date(timestamp);
        const diffMilli = date.getTime() - Date.now();
        return diffMilli < 0;
    }

    static getDiffMilli(timestamp) {
        const date = new Date(timestamp);
        return date.getTime() - Date.now();
    }

    static getNowState(secTimestamp1, secTimestamp2) {
        try {
            let num1 = Number(secTimestamp1);
            let num2 = Number(secTimestamp2);
            let curSec = Date.now() / 1000;
            if (curSec < num1) return -1;
            if (curSec > num2) return 1;
            return 0;
        } catch (e) {}
        return -2;
    }

    static comma(str) {
        try {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        } catch (e) {}
        return '';
    }

    static unComma(str) {
        try {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        } catch (e) {}
        return '';
    }

    static genderKr(str) {
        if (str == 'M') return '남';
        else if (str == 'F') return '여';
        return '';
    }

    static validationNumber(str) {
        let check = /^[0-9]+$/;
        return check.test(str);
    }

    //8자리 이상의 숫자만
    static validationPhone(str) {
        let check = /^\d{8,}$/;
        return check.test(str);
    }

    static validationEmail(str) {
        let check = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return check.test(str);
    }

    //영문 숫자 특수문자 포함 8이상
    static validationPassword(str) {
        let check = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return check.test(str);
    }

    static getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        let browserName;

        if (agent.indexOf('edge') > -1) {
            browserName = 'MS Edge'; // 예전 MS edge
        } else if (agent.indexOf('edg/') > -1) {
            browserName = '크로미움 기반 Edge';
        } else if (agent.indexOf('opr') > -1 && !!window.opr) {
            browserName = 'Opera'; // 오페라
        } else if (agent.indexOf('chrome') > -1 && !!window.chrome) {
            browserName = 'chrome'; // 크롬
        } else if (agent.indexOf('trident') > -1) {
            browserName = 'Internet explorer 11';
        } else if (agent.indexOf('msie') > -1) {
            browserName = 'Internet explorer'; // 11미만 버전
        } else if (agent.indexOf('firefox') > -1) {
            browserName = 'firefox'; // 파이어폭스
        } else if (agent.indexOf('safari') > -1) {
            browserName = 'safari'; // 사파리
        } else {
            browserName = 'other'; // 기타
        }

        return browserName;
    }

    static replaceEvenCharsWithAsterisk(text) {
        let result = '';
        for (let i = 0; i < text.length; i++) {
            if (i % 2 === 1) {
                result += '*';
            } else {
                result += text[i];
            }
        }
        return result;
    }

    static maskText(text, index = 2) {
        const maskedString = text.substring(0, index) + '*'.repeat(text.length - index);
        return maskedString;
    }
}
