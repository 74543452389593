import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';

// project import
import MainHeader from '../../pages/com/MainHeader';
import MainFooter from '../../pages/com/MainFooter';
import MainPage from '../../pages/main/MainPage';
import QuickService from '../../pages/main/QuickService';
import PageNavigation from '../../pages/com/PageNavigation';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const location = useLocation();
    const isRootPath = location.pathname === '/';

    return (
        <div id="wrap">
            <CookiesProvider>
                <MainHeader />
                {isRootPath ? (
                    <>
                        <MainPage />
                        {/* <QuickService /> */}
                    </>
                ) : (
                    <>
                        <div id="container">
                            <PageNavigation />
                            <Outlet />
                            {/* <QuickService /> */}
                        </div>
                    </>
                )}
                <MainFooter />
            </CookiesProvider>
        </div>
    );
};

export default MainLayout;
