import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseMenuGroupInfo } from '../../context';

const PageNavigation = () => {
    const location = useLocation();
    const menuGroup = UseMenuGroupInfo();
    const navigate = useNavigate();
    const [spreadMenuMain, setSpreadMenuMain] = useState(null);
    const [spreadMenuSub, setSpreadMenuSub] = useState(null);
    const locationRef = useRef(null);

    useEffect(() => {
        const handleMouseLeave = (event) => {
            setSpreadMenuMain(null);
            setSpreadMenuSub(null);
        };

        const locationBar = locationRef.current;

        if (locationBar) {
            locationBar.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (locationBar) {
                locationBar.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    let parentMenuName = null;
    let childMenuName = null;
    let children = null;
    if (menuGroup?.items) {
        for (let item of menuGroup.items) {
            for (let c of item.children) {
                if (c.url === location.pathname) {
                    childMenuName = c.title;
                    break;
                }
            }
            if (childMenuName != null) {
                children = item.children;
                parentMenuName = item.title;
                break;
            }
        }
    }

    const handleClickMain = (e) => {
        if (spreadMenuMain) setSpreadMenuMain(null);
        else setSpreadMenuMain(menuGroup.items);
        setSpreadMenuSub(null);
    };

    const handleClickSub = () => {
        if (spreadMenuSub) setSpreadMenuSub(null);
        else setSpreadMenuSub(children);
        setSpreadMenuMain(null);
    };

    let styleMain = { display: 'none', userSelect: 'none' };
    if (spreadMenuMain) styleMain = { display: 'block' };

    let styleSub = { display: 'none', userSelect: 'none' };
    if (spreadMenuSub) styleSub = { display: 'block' };

    return (
        <>
            <div className="locationBar" ref={locationRef}>
                <div className="inner">
                    <a href="/" className="h">
                        홈
                    </a>
                    <div className="location">
                        <a className="bt" onClick={handleClickMain}>
                            {parentMenuName}
                        </a>
                        <ul style={styleMain}>
                            {spreadMenuMain?.map((item, index) => {
                                return (
                                    <li>
                                        <a
                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                            key={index}
                                            onClick={() => {
                                                navigate(item?.children[0]?.url);
                                                setSpreadMenuMain(null);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="location">
                        <a className="bt" onClick={handleClickSub}>
                            {childMenuName}
                        </a>
                        <ul style={styleSub}>
                            {spreadMenuSub?.map((item, index) => {
                                return (
                                    <li>
                                        <a
                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                            key={index}
                                            onClick={() => {
                                                navigate(item.url);
                                                setSpreadMenuSub(null);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageNavigation;
