import PropTypes from 'prop-types';
import { useState } from 'react';

const SelectPopup = ({ message, onClose, callback, btnOkName, btnCancelName }) => {
    const handleOnClickOk = () => {
        if (callback) callback('ok');
        onClose();
    };

    const handleOnClickCancel = () => {
        if (callback) callback('cancel');
        onClose();
    };

    return (
        <>
            <div className="modal-container">
                <div className="pop-wr pop-sm" style={{ display: 'block' }}>
                    <div className="pop-hd"></div>
                    <div className="pop-con">
                        <div className="alert-bx" style={{ whiteSpace: 'pre-line' }}>
                            {message}
                        </div>
                        <div className="pop-bt">
                            <a className="btn primary" style={{ cursor: 'pointer' }} onClick={handleOnClickOk}>
                                {btnOkName}
                            </a>
                            <a className="btn close" style={{ cursor: 'pointer' }} onClick={handleOnClickCancel}>
                                {btnCancelName}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function useSelectPopup() {
    const [popup, setPopup] = useState(null);

    const openPopup = (message, callback, btnOkName = '확인', btnCancelName = '취소') => {
        setPopup({ message, callback, btnOkName, btnCancelName });
    };

    const closePopup = () => {
        setPopup(null);
    };

    const PopupComponent = popup ? (
        <SelectPopup
            message={popup.message}
            onClose={closePopup}
            callback={popup.callback}
            btnOkName={popup.btnOkName}
            btnCancelName={popup.btnCancelName}
        />
    ) : null;

    return [openPopup, PopupComponent];
}

SelectPopup.propTypes = {
    message: PropTypes.string,
    btnOkName: PropTypes.string,
    btnCancelName: PropTypes.string,
    onClose: PropTypes.func,
    callback: PropTypes.func
};

export default useSelectPopup;
