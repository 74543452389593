import { useEffect, useState } from 'react';

const MainFooter = () => {
    return (
        <footer id="mobile_footer">
            <div className="inner">
                <div className="f-wr">
                    <p className="logo">
                        <span className="blind">한국벤처캐피탈협회</span>
                    </p>
                    <div className="f-lnk">
                        <a href="/support/privacy">
                            <b>개인정보처리방침</b>
                        </a>
                        <a>이메일 무단수집거부</a>
                        <a href="/support/terms">이용약관</a>
                    </div>
                    <div className="f-fam">
                        <a className="bt">관련 사이트</a>
                        <ul>
                            <li>
                                <a href="http://diva.kvca.or.kr/" target="_blank">
                                    창업투자회사 전자공시시스템
                                </a>
                            </li>
                            <li>
                                <a href="http://www.peinvestor.net/" target="_blank">
                                    VC구주유통망
                                </a>
                            </li>
                            <li>
                                <a href="/contents/support/support4_1.html?sm=2_4_1_1" target="_blank">
                                    불공정행위신고센터
                                </a>
                            </li>
                            <li>
                                <a href="/en/contents/fiic_mission.html?sm=7_1" target="_blank">
                                    외국인투자기관협의회
                                </a>
                            </li>
                            <li>
                                <a href="/contents/support/support3_1.html?sm=2_3_1_1">벤처기업신청</a>
                            </li>
                            <li>
                                <a href="http://www.mna.go.kr" target="_blank">
                                    M&amp;A거래정보망
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <address>
                    <p>사업자번호: 116-82-03540</p>
                    <p>대표자 : 윤건수</p>
                    <p>TEL : 02-3474-9231</p>
                    <p>(06646)서울 서초구 반포대로28길 50 건한빌딩 4층</p>
                    <p>E-mail: edu@kvca.or.kr</p>
                    <p>FAX : 02-3474-9244</p>
                </address>
            </div>
        </footer>
    );
};

export default MainFooter;
