import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const MainNoticePopup = ({ notice, onClose }) => {
    const [dayCheck, setDayCheck] = useState(null);
    const checkboxRef = useRef(null);

    useEffect(() => {
        if (dayCheck === null) {
            let isExpired = check24DayClose();
            setDayCheck(isExpired ? 'POPUP_OK' : 'POPUP_DENY');
        }
    }, [dayCheck]);

    const check24DayClose = () => {
        const expireDay = localStorage.getItem('Popup24DayClose');
        if (!expireDay) return true;
        let today = new Date();
        return today.getTime() > expireDay;
    };

    const handleCloseClick = () => {
        if (checkboxRef.current?.checked) {
            const tomorrowDate = new Date();
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            tomorrowDate.setHours(0, 0, 0, 0);
            localStorage.setItem('Popup24DayClose', tomorrowDate.getTime());
        }
        onClose();
    };

    return (
        <>
            {' '}
            {notice && dayCheck == 'POPUP_OK' ? (
                <>
                    <div className="modal-container">
                        <div className="pop-wr pop-sm" style={{ display: 'block', width: '860px' }}>
                            <div className="pop-hd">{notice.noticeTitle}</div>
                            <div className="pop-con">
                                <div className="alert-bx" style={{ whiteSpace: 'pre-line' }}>
                                    <div
                                        style={{
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            maxHeight: '500px',
                                            overflowY: 'scroll',
                                            fontSize: '16px'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: notice ? notice.noticeContent : '' }}
                                    ></div>
                                </div>
                                <div className="pop-bt">
                                    <input ref={checkboxRef} type="checkbox" id="main_today" className="type2" />
                                    <label htmlFor="main_today">오늘 하루 더 이상 보지 않음</label>
                                </div>
                                <div className="pop-bt" style={{ marginTop: '8px' }}>
                                    <button type="button" className="btn close" onClick={handleCloseClick}>
                                        닫기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

MainNoticePopup.propTypes = {
    notice: PropTypes.object
};

export default MainNoticePopup;
