import req from '../utils/restApi';

// ==============================|| MENU ITEMS ||============================== //

async function menuItems(authInfo, dispatch) {
    const items = await req(
        '/api/menu/userMenus',
        {},
        (response) => {
            if (response.data?.childrenMenus) {
                let mainMenus = response.data.childrenMenus;
                for (let mainMenu of mainMenus) {
                    if (mainMenu.childrenMenus) {
                        mainMenu.childrenMenus = mainMenu.childrenMenus.slice().sort((a, b) => Number(a.sort) - Number(b.sort));
                    }
                }
                response.data.childrenMenus = mainMenus.slice().sort((a, b) => Number(a.sort) - Number(b.sort));

                return [
                    ...response.data.childrenMenus.map((parentMenu) => {
                        return {
                            id: parentMenu.menuId,
                            title: parentMenu.menuName,
                            type: 'group',
                            children: parentMenu.childrenMenus.map((menu) => {
                                return {
                                    id: menu.menuId,
                                    title: menu.menuName,
                                    type: 'item',
                                    url: menu.path,
                                    className: menu.icon
                                };
                            })
                        };
                    })
                ];
            }
            return [];
        },
        undefined,
        authInfo,
        dispatch
    );
    return { items };
}

export default menuItems;
