import { UseAuthInfo } from 'context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const QuickService = () => {
    const [fold, setFold] = useState(false);
    const [authInfo] = UseAuthInfo();
    const navigate = useNavigate();

    // useEffect(() => {
    //     setTimeout(function () {
    //         gnb('6', '1');
    //     }, 500);
    // }, []);

    const handleClickFold = () => {
        quickBtTopClick();
    };

    return (
        <>
            <div className="quick" style={{ zIndex: 1 }}>
                <h3 className="tit">빠른 서비스</h3>
                <ul>
                    <li>
                        <a href="#">
                            교육신청
                            <br />
                            안내
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            통장사본 ·<br />
                            사업자등록증
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            연간
                            <br />
                            교육일정
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            회원사
                            <br />
                            조회
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            담당자
                            <br />
                            연락처
                        </a>
                    </li>
                </ul>
                <button className="bt-top" onClick={handleClickFold}>
                    <span className="blind">top</span>
                </button>
            </div>
        </>
    );
};

export default QuickService;
