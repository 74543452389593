import { useEffect } from 'react';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import MiniNotice from './MiniNotice';

const MainPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/classroom/learningCourses');
        }, 100);
    }, []);
    return (
        <div style={{ minHeight: 800 }}>
            <MiniNotice />
        </div>
    );
};

export default MainPage;
